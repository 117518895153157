<template>
  <div class="about">
    <section class="hero">
      <div class="hero-body">
        <p class="title">Evolve</p>
        <p class="subtitle">Simulate natural selection</p>
        <p>(c)2021, Ramon Brülisauer</p>
      </div>
    </section>

    <div class="container content has-text-left">
      <h1>Abstract</h1>
      <p>
        This website simulates a 2-dimensional world, populated by little
        creatures. Each of these creatures has it's own brain, and a unique
        genome, which control their behaviour.
      </p>

      <h1>Key Concepts</h1>
      <h2>Creatures</h2>
      <p>
        Creatures live in one cell of the simulated world. They can move to all
        neighbour cells (8 directions) or stay where they are.<br />Each
        creature has sensory inputs to perceive it's surrounding world. There is
        also sensory inputs for randomness, age and an oscillating input.<br />Creatures
        have just two outputs neurons: Move X and Move Y.
      </p>

      <h2>Survival of the fittest</h2>
      <p>
        The simulation runs several generations. After each generation, some
        creatures die, while others survive and reproduce themselves.
      </p>

      <h2>Reproduction</h2>
      <p>
        Two random survivors are picked and their genome is mixed. One creature
        plays the role of the father, another is the mother. The new child
        genome has odd genes from it's father, and even genes from it's mother.
      </p>

      <h2>Artificial Brains</h2>
      <p>
        Each creature has an artificial brain, formed by a neural network. The
        connections in the brain are determined by the creature's genome.
      </p>

      <h2>Genomes</h2>
      <p>
        Each creature has a genome sequence, either random or inherited by it's
        parents. The genome consists of 32-bit numbers, each of them forms a
        single brain connection.
      </p>

      <h1>Related works</h1>
      <ul>
        <li>
          <a href="https://www.youtube.com/watch?v=N3tRFayqVtk"
            >https://www.youtube.com/watch?v=N3tRFayqVtk</a
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=0ZGbIKd0XrM"
            >https://www.youtube.com/watch?v=0ZGbIKd0XrM</a
          >
        </li>
      </ul>

      <h2>Powered by</h2>
      <ul>
        <li><a href="https://vuejs.org/">https://vuejs.org/</a></li>
        <li>
          <a href="https://www.typescriptlang.org/"
            >https://www.typescriptlang.org/</a
          >
        </li>
        <li>
          <a href="https://js.cytoscape.org/">https://js.cytoscape.org/</a>
        </li>
        <li>
          <a href="https://fontawesome.com/">https://fontawesome.com/</a>
        </li>
        <li>
          <a href="https://bulma.io/">https://bulma.io/</a>
        </li>
        <li>
          <a href="https://vuex.vuejs.org/">https://vuex.vuejs.org/</a>
        </li>
        <li>
          <a href="https://github.com/championswimmer/vuex-module-decorators"
            >https://github.com/championswimmer/vuex-module-decorators</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
